<script setup lang="ts">
  import InstagramAccountEdition from '@/components/instagram/AccountEdition.vue'

  import type { Challenge } from '~/types/challenge'

  const { hash } = useRoute()
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const SectionName = 'FU-Missions'

  const props = defineProps<{
    challenges: Challenge[]
    anchorIgnore: boolean
  }>()

  const emit = defineEmits<{
    'open-anchor': []
  }>()

  const selectedChallenge = ref<Challenge | null>(null)
  const showChallengeModal = ref(false)
  const openFromAnchor = ref(false)

  onMounted(() => {
    if (props.anchorIgnore) return

    const hashFlag = '#mission-'
    if (hash.includes(hashFlag)) {
      const missionId = +hash.split(hashFlag)[1]
      selectedChallenge.value = props.challenges.find((challenge) => challenge.id === missionId) || null
      if (selectedChallenge.value !== null) {
        openFromAnchor.value = true
        showChallengeModal.value = true
        emit('open-anchor')
      }
    }
  })

  const compareChallengePosition = (challenge1: Challenge, challenge2: Challenge) => {
    return challenge1.position_index - challenge2.position_index
  }

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_challenge.card,
      challengeName: extractTypography('branding_challenge', 'mission_text', 'primary'),
      challengePoints: extractTypography('branding_challenge', 'point_text', 'secondary'),
      actionButton: extractButton('branding_challenge', 'action_btn')
    }
  })

  const challengesSorted = computed((): Challenge[] => {
    const challenges = props.challenges
    return challenges.sort(compareChallengePosition)
  })

  const { data: creator } = useStoreCreator().fetchCreator()
  const showModalTiktok = ref(false)

  const instagramAccountEdition = ref<InstanceType<typeof InstagramAccountEdition> | null>(null)
  const challengeSelected = ref<Challenge | null>(null)

  const onClick = (challenge: Challenge) => {
    if (isAuthenticated.value && creator.value) {
      const { social_network } = challenge
      const { tiktok_account, insta_account } = creator.value

      if (social_network === 'tiktok' && tiktok_account === null) {
        showModalTiktok.value = true
        return
      }

      if (
        social_network === 'instagram' &&
        (insta_account === null || insta_account.is_private === null || insta_account.is_private)
      ) {
        challengeSelected.value = challenge
        instagramAccountEdition.value!.handleAccountEdition()
        return
      }

      if (social_network === 'tiktok' && (challenge.min_followers ?? 0) > tiktok_account.followers_count) {
        return
      }
      if (social_network === 'instagram' && (challenge.min_followers ?? 0) > insta_account.followers_count) {
        return
      }

      gotoChallenge(challenge)
    }
  }
  const gotoChallenge = (challenge: Challenge | null) => {
    if (!challenge) return

    challengeSelected.value = null

    const { id, name, type_challenge } = challenge

    useTrack().track(SectionName, {
      mission_id: id,
      mission_name: name,
      mission_template: type_challenge
    })

    selectedChallenge.value = challenge
    showChallengeModal.value = true
  }

  const onModalClose = () => {
    openFromAnchor.value = false
    showChallengeModal.value = false
    selectedChallenge.value = null
  }

  // login or register
  const showModalLogin = ref(false)
  const showModalRegister = ref(false)
  const showModalForgottenPassword = ref(false)
  const showModalForgottenPasswordSuccess = ref(false)
  const requestNewPasswordEmail = ref('')

  const onLoginFromModal = () => {
    showModalRegister.value = false
    showModalLogin.value = true
  }

  const onRegisterFromModal = () => {
    showModalRegister.value = true
    showModalLogin.value = false
  }

  const onForgottenPasswordFromModal = () => {
    showModalLogin.value = false
    showModalRegister.value = false
    showModalForgottenPassword.value = true
  }

  const onSuccessPasswordForgotten = (email: string) => {
    showModalForgottenPassword.value = false
    requestNewPasswordEmail.value = email
    showModalForgottenPasswordSuccess.value = true
  }
</script>

<template>
  <template v-if="section">
    <CardChallenge
      v-for="challenge in challengesSorted"
      :id="challenge.id"
      :key="challenge.id"
      class="card-challenge"
      :class="{ authenticated: isAuthenticated }"
      :background-color="section.card.background_color"
      :hover-background-color="section.card.hover_background_color"
      :border-color="section.card.border_color"
      :border-radius="section.card.radius"
      :challenge-points="section.challengePoints"
      :image-url="challenge.icon_url"
      :challenge-name="section.challengeName"
      @click="onClick(challenge)"
    >
      <template #points>
        {{ $t('PointsDecimal', { value: $n(challenge.points_value) }, Math.floor(challenge.points_value)) }}
      </template>
      <template #name>
        {{ challenge.name }}
      </template>
      <template
        v-if="!isAuthenticated"
        #hover-content
      >
        <LoginOrRegister
          :track-section="SectionName"
          @login="showModalLogin = true"
          @register="showModalRegister = true"
        />
      </template>
    </CardChallenge>
    <TiktokModalNotLinked
      :show="showModalTiktok"
      @close="showModalTiktok = false"
    />
    <InstagramAccountEdition
      ref="instagramAccountEdition"
      from-challenge
      @goto-challenge="gotoChallenge(challengeSelected)"
    />
    <SectionChallengesModalLoyaltyDetail
      v-if="selectedChallenge && selectedChallenge.type === 0"
      :open-from-anchor="openFromAnchor"
      :challenge="selectedChallenge"
      :show="showChallengeModal"
      @close="onModalClose"
    />
    <SectionChallengesModalDetail
      v-if="selectedChallenge && selectedChallenge.type !== 0"
      :open-from-anchor="openFromAnchor"
      :challenge="selectedChallenge"
      :show="showChallengeModal"
      @close="onModalClose"
    />
    <template v-if="!isAuthenticated">
      <ModalLogin
        v-if="showModalLogin"
        @close="showModalLogin = false"
        @register="onRegisterFromModal"
        @forgotten-password="onForgottenPasswordFromModal"
      />
      <ModalRegister
        v-if="showModalRegister"
        @close="showModalRegister = false"
        @login="onLoginFromModal"
      />
      <ModalPasswordForgotten
        v-if="showModalForgottenPassword"
        @close="showModalForgottenPassword = false"
        @success="onSuccessPasswordForgotten"
      />
      <ModalPasswordForgottenSuccess
        v-if="showModalForgottenPasswordSuccess"
        :email="requestNewPasswordEmail"
        @close="showModalForgottenPasswordSuccess = false"
      />
    </template>
  </template>
</template>

<style scoped>
  .card-challenge {
    flex-shrink: 0;

    &.authenticated {
      cursor: pointer;
    }
  }
</style>
