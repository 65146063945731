export const defaultCurrency = 'EUR'

const MoneyCode = {
  EUR: '€',
  USD: '$',
  CHF: 'CHF',
  ARS: '$',
  AUD: '$',
  BRL: 'R$',
  CAD: '$',
  DKK: 'Kr',
  GBP: '£',
  JPY: '¥',
  HRK: 'Kn',
  MAD: 'د.م.',
  MXN: '$',
  NOK: 'kr',
  NZD: '$',
  SGD: '$',
  INR: '₹',
  PLN: 'zł',
  AED: 'د.إ'
}

export const getMoneySymbol = (currency: string | undefined) => {
  return MoneyCode[currency as keyof typeof MoneyCode] || MoneyCode[defaultCurrency]
}
