<script setup lang="ts">
  import type { ChallengeDisplay } from '~/types/challenge'

  const props = defineProps<{
    display: ChallengeDisplay
  }>()

  const isVideo = ref(false)

  onBeforeMount(async () => {
    const xhr = new XMLHttpRequest()
    xhr.open('HEAD', props.display.value!, true)
    xhr.responseType = 'document'
    xhr.onload = function () {
      const contentType = xhr.getResponseHeader('Content-Type')
      isVideo.value = contentType && contentType.includes('video') ? true : false
    }
    xhr.send()
  })
</script>

<template>
  <div class="mission-media">
    <video
      v-if="isVideo"
      class="mission-media__content-video"
      controls
    >
      <source
        :src="display.value"
        preload="metadata"
        type="video/mp4"
      />
    </video>
    <img
      v-else
      :src="display.value"
      alt=""
      class="mission-media__content-image"
    />
  </div>
</template>
<style scoped>
  .mission-media {
    display: flex;
    justify-content: center;

    &__content-image,
    &__content-video {
      object-fit: cover;
      max-height: 250px;
      width: 250px;

      @media (min-width: 768px) {
        max-height: 300px;
        width: 300px;
      }

      @media (min-width: 1024px) {
        max-height: 400px;
        width: 400px;
      }
    }
  }
</style>
