<script setup lang="ts">
  import type { ClaimedReward, Reward } from '~/types/reward'

  const { t, te } = useI18n()
  const companyId = parseInt(useRoute().params.companyId as string, 10)

  const { isMobile } = storeToRefs(useStoreApp())
  const { getCreatorBalance } = useStoreCreator()
  const { data: balance } = getCreatorBalance(companyId)
  const { data: creator } = useStoreCreator().fetchCreator()
  const { claimReward } = useStoreReward()
  const { mutateAsync, isPending, error: claimError } = claimReward()
  const footerRef = ref()

  const props = defineProps<{
    reward: Reward
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
    claim: [ClaimedReward]
  }>()

  onMounted(() => {
    useTrack().track('modal-rewards-details', {
      email: creator.value?.email ?? '',
      reward_type: props.reward.promo_type
    })
  })

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_reward.card,
      rewardName: extractTypography('branding_reward', 'reward_text', 'primary'),
      rewardPoints: extractTypography('branding_reward', 'point_text', 'secondary'),
      actionButton: extractButton('branding_reward', 'action_btn')
    }
  })

  const canExchange = computed(() => {
    return props.reward.threshold <= (balance.value || 0)
  })

  const onClaim = async () => {
    useTrack().track('click-reward-exchange', {
      email: creator.value!.email,
      reward_type: props.reward.promo_type,
      reward_id: props.reward.id
    })

    const res = await mutateAsync({ companyId: companyId, rewardId: props.reward.id })
    emit('claim', res)
  }

  const claimErrorFormatted = computed(() => {
    if (!claimError.value) return ''
    const errorMsg = Object.values(claimError.value['response']._data as { string: string }).shift()
    const transKey = `ApiErrors.${errorMsg}`
    return te(transKey) ? t(transKey) : t('ApiErrors.generic')
  })

  const shouldTeleportBtnsToFooter = computed(() => {
    return footerRef.value && isMobile.value
  })
</script>

<template>
  <ModalApp
    class="reward-detail-modal"
    footer-fixed
    :show
    :no-footer="!isMobile"
    @close="emit('close')"
  >
    <SectionRewardsDetailInfos :reward="reward">
      <template #btn>
        <ClientOnly>
          <Teleport
            :to="footerRef"
            :disabled="!shouldTeleportBtnsToFooter"
          >
            <div class="reward-detail-modal__btn">
              <ButtonBranding
                :button-branding="section!.actionButton"
                type="submit"
                :loading="isPending"
                :disabled="!canExchange"
                @click="onClaim"
              >
                {{ $t('SectionRewardsDetailModal.exchange') }}
              </ButtonBranding>
              <FormError v-if="claimError">
                {{ claimErrorFormatted }}
              </FormError>
            </div>
          </Teleport>
        </ClientOnly>
      </template>
    </SectionRewardsDetailInfos>
    <template #footer>
      <div
        ref="footerRef"
        class="reward-detail-modal__footer"
      />
    </template>
  </ModalApp>
</template>

<style scoped>
  .reward-detail-modal {
    &__footer {
      width: 100%;
      text-align: center;

      .lds-form-error {
        margin-top: 5px;
      }
    }
    &__btn {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: column;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }
</style>
