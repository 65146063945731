import type { ClaimedReward } from '~/types/reward'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { ApiErrorClass } from '~/types/apiError'

export const useStoreReward = defineStore('reward', () => {
  const queryClient = useQueryClient()

  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const { isPreview } = storeToRefs(useStoreApp())

  const getRewards = (companyId: number) => {
    return useQuery({
      queryKey: ['listRewards', companyId, isAuthenticated],
      queryFn: async () => {
        return await useNuxtApp().$api(`/api/creator/company/${companyId}/rewards/`)
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  const getClaimedRewards = (companyId: number) => {
    return useQuery({
      queryKey: ['listClaimedRewards', companyId, isAuthenticated],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/claims/`)) as ClaimedReward[]
      },
      staleTime: 1800000, // 30 minutes
      enabled: !isPreview.value
    })
  }

  const claimReward = () => {
    return useMutation({
      mutationFn: async ({ companyId, rewardId }: { companyId: number; rewardId: number }) => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/claims/`, {
          method: 'POST',
          body: {
            reward_id: rewardId
          }
        })) as ClaimedReward
      },
      onSuccess: () => {
        // cf. https://github.com/TanStack/query/issues/4669 multiple invalidateQueries
        queryClient.invalidateQueries({ queryKey: ['listRewards'] })
        queryClient.invalidateQueries({ queryKey: ['listClaimedRewards'] })
        queryClient.invalidateQueries({ queryKey: ['fetchCreatorBalance'] })
      },
      onError: (error: ApiErrorClass) => {
        return error
      }
    })
  }

  return { getRewards, getClaimedRewards, claimReward }
})
