<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      show: boolean
      footerFixed?: boolean
      fullPage?: boolean
      noFooter?: boolean
    }>(),
    {
      footerFixed: false,
      fullPage: false,
      noFooter: false
    }
  )

  const emit = defineEmits<{
    close: [mouseEvent: MouseEvent]
  }>()

  const buttonBack = computed(() => useBrandingButton().extractButton('branding_general', 'back_btn'))

  const { isPreview, isModalFullPage, modalsOpenedCount } = storeToRefs(useStoreApp())

  const onClose = (event: MouseEvent) => {
    if (isPreview.value) return

    emit('close', event)
  }

  watch(
    () => props.show,
    (value) => {
      if (props.fullPage) {
        isModalFullPage.value = value
      }

      modalsOpenedCount.value = document.querySelectorAll('.lds-modal__container').length

      if (value) {
        window.parent.postMessage({ scrollTop: true, isWeWiink: true }, '*')
      }
    },
    { immediate: true }
  )

  onUnmounted(() => {
    if (props.fullPage) {
      isModalFullPage.value = false
    }
  })
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <ModalBase
        :class="!useStoreApp().isAppEmbedded ? 'not-embedded' : 'embedded'"
        :show="show"
        :footer-fixed="footerFixed"
        :full-page="fullPage"
        top="0px"
        :no-footer="noFooter"
        :header-background-color="buttonBack.backgroundColor"
        footer-background-color="#F9F9F9"
        :header-hover-background-color="buttonBack.hoverBackgroundColor"
        :header-text-color="buttonBack.textColor"
        :header-hover-text-color="buttonBack.hoverTextColor"
        :header-text-font-size="buttonBack.fontSize"
        :header-text-font-weight="buttonBack.weight"
        @close="onClose"
      >
        <template #header>
          {{ buttonBack.text }}
        </template>

        <slot />

        <template #footer>
          <slot name="footer" />
        </template>
      </ModalBase>
    </Teleport>
  </ClientOnly>
</template>

<style scoped>
  .embedded {
    :deep(.lds-modal__content) {
      min-height: 500px;
    }

    :deep(.lds-modal__container) {
      height: 100%;
      top: 0;
      transition: none;

      @media (min-width: 768px) {
        height: unset;
        width: 100%;
        top: 0;
        &-full-page {
          height: unset;
          top: 0;
        }
      }
    }
  }

  .not-embedded {
    display: flex;
    :deep(.lds-modal__container) {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
      height: 100dvh;
    }
  }
</style>
