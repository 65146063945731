<script setup lang="ts">
  import type { ButtonBaseProps } from '~/types/design-system'
  import { useBrandingButton } from '~/composables/useBrandingButton.js'
  import type { ButtonBranding } from '~/types/branding'

  const { isPreview } = storeToRefs(useStoreApp())

  const props = withDefaults(
    defineProps<{
      trackSection: string
      type?: ButtonBaseProps['type']
      loading?: boolean
      branding?: ButtonBranding
    }>(),
    {
      type: 'button',
      loading: false,
      branding: undefined
    }
  )

  const emit = defineEmits<{
    login: []
  }>()

  const buttonSignIn = computed(
    () => props.branding || useBrandingButton().extractButton('branding_general', 'signin_btn')
  )

  const trackClick = () => {
    useTrack().track('click-to-login', { section: props.trackSection })
  }

  const storeStore = useStoreStore()
  const onClick = () => {
    if (isPreview.value) return
    trackClick()
    if (useEmbedded().isEmbedded.value) {
      const url = storeStore.store?.url
      if (storeStore.store?.provider === 1) {
        navigateExternalUrl(`${url}/account/login`)
      } else {
        navigateExternalUrl(`${url}/index.php?controller=my-account`)
      }

      return
    }
    emit('login')
  }
</script>

<template>
  <ButtonBranding
    :button-branding="buttonSignIn"
    :type
    :loading
    @click="onClick"
  >
    {{ buttonSignIn.text }}
  </ButtonBranding>
</template>
