<script setup lang="ts">
  import type { ContentAlignement } from '~/types/design-system'
  import type { Challenge } from '~/types/challenge'

  const { isPreview } = storeToRefs(useStoreApp())
  const { companyId } = useStoreApp()
  const { getChallenges, getLoyaltyChallenges } = useStoreChallenge()
  const { isPending, data } = getChallenges(companyId)
  const { data: dataLoyalty } = getLoyaltyChallenges(companyId)
  const storeStore = useStoreStore()

  const { isAuthenticated } = storeToRefs(useStoreAuth())

  const challenges = computed((): Challenge[] => {
    if (!data.value || !dataLoyalty.value) {
      return []
    }

    const loyaltyChalls = dataLoyalty.value.map((l) => ({
      ...l,
      points_value: +(l.points_value / storeStore.currencyRate).toFixed(1)
    }))
    return [...loyaltyChalls, ...data.value.filter(filterActiveChallenge)]
  })

  const filterActiveChallenge = (challenge: Challenge) => {
    // 41: account creation
    if (challenge.challenge_template_id === 41) {
      return !isAuthenticated.value
    }

    return challenge.enable && challenge.status !== 'SUC' // success
  }

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const {
      id,
      section_color,
      layout,
      section_title: { alignment }
    } = brandings.branding_challenge

    return {
      id,
      isCarouselLayout: isCarouselLayout(layout),
      backgroundColor: section_color,
      title: extractTypography('branding_challenge', 'section_title', 'primary'),
      alignment
    }
  })

  const titleAlignment = computed((): ContentAlignement => {
    if (!section.value) return 'left'

    return getContentAlignment(section.value.alignment)
  })

  const showAllModal = ref(false)

  const handleClickShowAll = () => {
    if (isPreview.value) return
    showAllModal.value = true
  }

  const hasOpenedAnchor = ref(false)
</script>

<template>
  <SectionAnchored
    anchor-id="missions"
    anchor-id-modal="mission"
  >
    <SectionBase
      v-if="section"
      :id="section.id"
      class="challenges-wrapper"
      :background-color="section.backgroundColor"
    >
      <SectionSkeletonTitle v-if="isPending" />
      <div
        v-else
        class="challenges-wrapper__title"
      >
        <TypographyBase
          :id="section.title.id"
          tag="h2"
          class="challenges-wrapper__title--text text-primary-heading-2"
          :font-weight="section.title.fontWeight"
          :font-size="section.title.fontSize"
          :color="section.title.color"
          :font-family="section.title.fontFamily"
          :text-align="section.title.textAlign"
        >
          {{ section.title.text }}
        </TypographyBase>
        <ButtonShowAll
          v-if="section.isCarouselLayout"
          @click="handleClickShowAll"
        >
          {{ $t('SectionChallenges.showAll') }}
        </ButtonShowAll>

        <ModalShowAll
          :show="showAllModal"
          :title="section.title"
          @close="showAllModal = false"
        >
          <div class="challenges__modal-show-all-grid">
            <SectionChallengesCardList
              v-if="challenges.length > 0"
              :anchor-ignore="hasOpenedAnchor"
              :challenges="challenges"
            />
            <SectionEmpty v-else>{{ $t('SectionChallenges.empty') }}</SectionEmpty>
          </div>
        </ModalShowAll>
      </div>

      <SectionEmpty v-if="!isPending && !challenges.length">{{ $t('SectionChallenges.empty') }}</SectionEmpty>

      <CarouselBase
        v-if="section.isCarouselLayout"
        class="challenges-wrapper__carousel"
        buttons-background-color="#000"
        buttons-hover-background-color="#000"
        buttons-text-color="#fff"
        buttons-hover-text-color="#fff"
        :buttons-radius="0"
        :slide-width="300"
      >
        <template v-if="isPending">
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
        </template>
        <SectionChallengesCardList
          v-else-if="challenges.length > 0"
          :anchor-ignore="hasOpenedAnchor"
          :challenges="challenges"
          @open-anchor="hasOpenedAnchor = true"
        />
      </CarouselBase>
      <div
        v-else
        class="challenges-wrapper__container"
      >
        <template v-if="isPending">
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
          <SectionChallengesCardSkeleton />
        </template>
        <SectionChallengesCardList
          v-else-if="challenges.length > 0"
          :anchor-ignore="hasOpenedAnchor"
          :challenges="challenges"
          @open-anchor="hasOpenedAnchor = true"
        />
      </div>
    </SectionBase>
  </SectionAnchored>
</template>

<style scoped>
  .challenges-wrapper {
    :deep(.lds-section__content) {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 768px) {
        gap: 40px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: v-bind(titleAlignment);

      &--text {
        width: auto;
      }
    }

    &__carousel {
      height: 222px; /* card height */

      @media (min-width: 768px) {
        height: 300px;
      }
    }

    &__container {
      display: grid;
      place-content: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (min-width: 480px) {
        column-gap: 40px;
      }

      @media (min-width: 768px) {
        gap: 70px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 42.5px;
      }

      @media (min-width: 1440px) {
        gap: 70px;
      }
    }
  }
  .challenges__modal-show-all-grid {
    display: grid;
    place-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (min-width: 480px) {
      gap: 40px;
    }

    @media (min-width: 768px) {
      column-gap: 70px;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }
  }
</style>
