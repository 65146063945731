<script setup lang="ts">
  const companyId = useRoute().params.companyId as string

  const { data: companyCreator } = useStoreCreator().getCompanyCreator(parseInt(companyId))
  const { updateCompanyCreator } = useStoreCreator()
  const { mutate } = updateCompanyCreator()

  defineProps<{
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
  }>()

  const optins = computed(() => {
    return {
      optinMissions: companyCreator?.value?.optin_missions || false,
      optinRewards: companyCreator?.value?.optin_rewards || false
    }
  })

  const onChange = () => {
    mutate({
      creatorId: companyCreator.value!.id,
      optinMissions: optins.value.optinMissions,
      optinRewards: optins.value.optinRewards
    })
  }
</script>

<template>
  <ModalApp
    class="notifications-modal"
    :show
    no-footer
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('Modal.Notifications.title') }}</h1>
    <h2 class="text-primary-heading-2">{{ $t('Modal.Notifications.information.title') }}</h2>
    <p class="text-secondary-body">{{ $t('Modal.Notifications.information.text') }}</p>
    <FormGroup>
      <FormCheckbox
        v-model="optins.optinMissions"
        @change="onChange"
      >
        {{ $t('Modal.Notifications.communication.missions') }}
      </FormCheckbox>
      <FormCheckbox
        v-model="optins.optinRewards"
        @change="onChange"
      >
        {{ $t('Modal.Notifications.communication.rewards') }}
      </FormCheckbox>
    </FormGroup>
  </ModalApp>
</template>
