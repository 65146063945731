<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const emit = defineEmits<{
    close: []
  }>()

  const companyId = useRoute().params.companyId as string

  const { data: activities, isPending } = useStoreCreator().getCreatorActivities(parseInt(companyId, 10), 5)

  const { t } = useI18n()
  const eventTextMap = new Map()
  eventTextMap.set(0, t('SectionSteps.ModalActivities.events.challenge'))
  eventTextMap.set(1, t('SectionSteps.ModalActivities.events.order'))
  eventTextMap.set(2, t('SectionSteps.ModalActivities.events.reward'))
  eventTextMap.set(3, t('SectionSteps.ModalActivities.events.refferal'))
  eventTextMap.set(4, t('SectionSteps.ModalActivities.events.userAction'))
  eventTextMap.set(5, t('SectionSteps.ModalActivities.events.level'))

  const { data: creator } = useStoreCreator().fetchCreator()

  onMounted(() => {
    useTrack().track('modal-recent-activities', {
      email: creator.value!.email
    })
  })
</script>

<template>
  <ModalApp
    show
    no-footer
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('SectionSteps.ModalActivities.title') }}</h1>

    <div
      v-if="isPending || !activities?.results"
      class="activities-pending"
    >
      <IconLoader
        color="#000"
        width="48px"
        height="48px"
      />
    </div>
    <TableBase
      v-else-if="activities.results.length"
      grid-template-columns="1fr 150px"
    >
      <TableCellHeader>
        {{ $t('SectionSteps.ModalActivities.table.header.title') }}
      </TableCellHeader>
      <TableCellHeader>
        {{ $t('SectionSteps.ModalActivities.table.header.points') }}
      </TableCellHeader>
      <template
        v-for="(activity, idx) in activities.results"
        :key="`activity_${idx}`"
      >
        <TableCell>{{ eventTextMap.get(activity.type_event) }}</TableCell>
        <TableCell>{{ activity.amount }}</TableCell>
      </template>
    </TableBase>
    <SectionEmpty v-else>
      {{ $t('SectionSteps.ModalActivities.empty') }}
    </SectionEmpty>
  </ModalApp>
</template>

<style scoped>
  .activities-pending {
    height: 100%;
    display: grid;
    place-items: center;
  }

  :deep(.lds-modal__content) {
    height: 100%;
  }
</style>
