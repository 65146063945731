<script setup lang="ts">
  import type { ChallengeDisplay } from '~/types/challenge'

  const props = defineProps<{
    display: ChallengeDisplay
  }>()

  const branding = useBrandingButton().extractButton('branding_challenge', 'action_btn')

  const goToLink = () => {
    window.open(props.display.value, '_blank')
  }
</script>

<template>
  <ButtonBrandingInverted
    class="text-primary-heading-2"
    :button-branding="branding"
    @click="goToLink"
  >
    {{ $t(`SectionChallenges.ModalMissionDetail.${display.text}`) }}
  </ButtonBrandingInverted>
</template>
