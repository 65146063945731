<script setup lang="ts">
  const { extractTypography } = useBrandingTypography()

  const { brandings } = storeToRefs(useStoreBranding())
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const { isPreview } = storeToRefs(useStoreApp())

  const extractStep = (stepIndex: number) => {
    return {
      stepNumber: extractTypography('branding_step', `title_step_${stepIndex}`, 'primary'),
      stepTitle: extractTypography('branding_step', `text_step_${stepIndex}`, 'primary'),
      stepDescription: extractTypography('branding_step', `description_step_${stepIndex}`, 'secondary')
    }
  }

  const stepSection = computed(() => {
    const brandingStep = brandings.value?.branding_step

    if (!brandingStep) return

    const { id, section_color } = brandingStep

    return {
      id,
      backgroundColor: section_color,
      steps: [extractStep(1), extractStep(2), extractStep(3)]
    }
  })
</script>

<template>
  <SectionBase
    v-if="stepSection"
    :background-color="stepSection.backgroundColor"
  >
    <SectionStepsAuthenticated v-if="isAuthenticated && !isPreview" />
    <SectionStepsNotAuthenticated v-else />
  </SectionBase>
</template>
