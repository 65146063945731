<script setup lang="ts">
  import type { Creator } from '~/types/creator'

  const { extractTypography } = useBrandingTypography()
  const { extractButton } = useBrandingButton()
  const { getCreatorBalance } = useStoreCreator()

  const { companyId } = useRoute().params
  const { data: balance } = getCreatorBalance(parseInt(companyId as string, 10))
  const { data: creator } = useStoreCreator().fetchCreator()

  const stepConnected = computed(() => {
    const title = extractTypography('branding_step', 'title_connected', 'primary')
    const subtitle = extractTypography('branding_step', 'subtitle_connected', 'secondary')

    let firstName = ''
    if (creator.value) {
      firstName = (creator.value as Creator).first_name
    }

    title.text = title.text!.replace('{{first_name}}', firstName)

    subtitle.text = subtitle.text!.replace('{{points_amount}}', String(balance.value))

    return {
      title,
      subtitle,
      buttonReward: extractButton('branding_step', 'reward_btn'),
      buttonActivity: extractButton('branding_step', 'activity_btn')
    }
  })
</script>

<template>
  <StepConnected
    :step-title="stepConnected.title"
    :step-subtitle="stepConnected.subtitle"
  >
    <template #title>
      {{ stepConnected.title.text }}
    </template>
    <template #subtitle>
      {{ stepConnected.subtitle.text }}
    </template>
    <SectionStepsButtonRewards />
    <SectionStepsButtonActivities />
  </StepConnected>
</template>
